<template>
    <div>
        <!--
            START Podatki o naročilu
        -->
        <div class="col-md-4">

        
        <FormPanelSection
            title="Podatki o naročilu"
        >
            <!-- Datumi -->
            <h3 class="box-title">Datumi</h3><hr>
            <div class="row">
                <div class="col-sm-12">
                    <FormulateInput
                        name="created_at"
                        :label="$t('depository:client_submitted_date')"
                        type="date"
                        :disabled="true"
                        :fromToday="true"
                        prependIcon="ti-calendar"
                    />
                    <FormulateInput
                        name="due_date"
                        label="Rok izdelave"
                        type="date"
                        help="Datum, do katerega mora biti izdelana cenitev"
                        :disabled="isReadOnly"
                        :fromToday="true"
                        prependIcon="ti-calendar"
                    />
                    <FormulateInput
                        v-if="showQuoteDueDate"
                        name="quote_due_date"
                        label="Rok za oddajo ponudb"
                        type="date"
                        help="Datum, do katerega morajo biti oddane ponudbe"
                        :disabled="isReadOnly"
                        :fromToday="true"
                        prependIcon="ti-calendar"
                    />

                    <!--
                        START Order file
                    -->
                    <label>
                        Naročilnica
                        <HelpIconStatic
                            v-if="!isReadOnly"
                            title="Naročilnica"
                            text="Naložite lahko xlsx dokument v predpisani obliki, podatki pa se nato avtomatsko preberejo in izpolnijo v ustrezna polja znotraj tega naročila."
                        />
                    </label>

                    <FormulateInput
                        v-if="!isReadOnly"
                        type="file"
                        prependIcon="ti-file"
                        accept=".xlsx"
                        class="m-b-10"

                        @handle-upload="handleUpload"
                    />

                    <div
                        v-if="formValues.order_file"
                        class="m-b-20"
                    >
                        <button
                            class="btn full-width breakable-button"
                            @click="onDownloadOrderFile"
                        >
                            Prenesi naročilnico {{ getOrderFileName }}
                        </button>
                        <button
                            v-if="!isReadOnly"
                            class="btn m-t-10 full-width breakable-button"
                            @click="$emit('import-order-file')"
                        >
                            Uvozi podatke iz naročilnice
                        </button>
                    </div>
                    <div v-else-if="isReadOnly" class="m-b-20">
                        Naročilnica ni bila naložena.
                    </div>
                    <!--
                        END Order file
                    -->

                </div>
            </div>

            <!-- Naročnik -->
            <h3 class="box-title">Naročnik</h3><hr>
            <div class="row">
                <div class="col-sm-12">
                    <FormulateInput
                        name="client_title"
                        :label="$t('depository:client_title')"
                        type="text"
                        help="Ime in priimek naročnika"
                        :disabled="isReadOnly"
                        prependIcon="ti-user"
                    />
                </div>
                <div class="col-sm-12">
                    <FormulateInput
                        name="client_email"
                        :label="$t('depository:client_email')"
                        type="email"
                        help="Email naslov naročnika"
                        :disabled="isReadOnly"
                        prependIcon="ti-email"
                        errorMessage="Email naslov ni veljaven"
                        pattern="^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+.[a-zA-Z]{2,6}$"
                    />
                </div>
            </div>

            <!-- Cenilec -->
            <template v-if="showAppraiserSelection">
                <h3 class="box-title">Cenilec</h3><hr>
                <div class="row">
                    <div class="col-sm-12 m-b-20">
                        <label>Skupine, ki jim bo naročilo vidno</label>
                        <div class="input-group">
                            <i class="input-group-addon ti-layers"></i>
                            <Multiselect
                                v-model="appraiserGroups"
                                :options="Object.keys(fakeAppraisers)"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                placeholder="Izberite skupine"
                                :select-label="$t('general:select')"
                                :selected-label="$t('general:selected')"
                                :deselect-label="$t('general:remove')"
                                :disabled="isReadOnly"
                            >
                                <template slot="noResult">
                                    {{ $t('general:search:no_results')}}
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <label>Cenilci, ki jim bo naročilo vidno</label>
                        <div class="input-group">
                            <i class="input-group-addon ti-user"></i>
                            <Multiselect
                                v-model="appraiserUsers"
                                :options="appraiserUserOptions"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                placeholder="Izberite cenilce"
                                :select-label="$t('general:select')"
                                :selected-label="$t('general:selected')"
                                :deselect-label="$t('general:remove')"
                                :disabled="isReadOnly"
                            >
                                <template slot="noResult">
                                    {{ $t('general:search:no_results')}}
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                </div>
            </template>
        </FormPanelSection>

        </div>
        <!--
            END Podatki o naročilu
        -->

        <slot>
            <div><!-- This default slot solves a bug where FormValues was overriden unexpectedly. --></div>
        </slot>

        <!--
            START Predmet vrednotenja
        -->
        <div class="col-md-8">
            <FormPanelSection
                title="Predmet vrednotenja"
            >
                <OrderFormAppraisalTable
                    :orderitems="formValues.orderitem_set"
                    :isReadOnly="isReadOnly"
                />
            </FormPanelSection>
        </div>
        <!--
            END Predmet vrednotenja
        -->
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import OrderFormRealEstateTable from "./order_form_parts/OrderFormRealEstateTable.vue";
import OrderFormAppraisalTable from "./order_form_parts/OrderFormAppraisalTable.vue";
import FormPanelSection from "./FormPanelSection.vue";
import HelpIconStatic from "@/components/widgets/HelpIconStatic";

export default {
    components: {
        OrderFormAppraisalTable,
        OrderFormRealEstateTable,
        FormPanelSection,
        Multiselect,
        HelpIconStatic,
    },
    props: {
        isReadOnly: {
            type: Boolean,
            default: false,  
        },
        formValues: {
            type: Object,
            default: null,
        },
        showAppraiserSelection: {
            type: Boolean,
            default: true,
        },
        showQuoteDueDate: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            // fake data for selecting appraisers
            fakeAppraisers: {
                "Arvio": [
                    "Boštjan Ocenjevalec",
                    "Janez cenilec",
                    "Ajda Cenilka",
                ],
                "Skupina 1": [
                    "Cenilec 11",
                    "Cenilec 12",
                    "Cenilec 13",
                ],
                "Skupina 2": [
                    "Cenilec 21",
                    "Cenilec 22",
                    "Cenilec 23",
                ],
            },
            appraiserGroups: [],
            appraiserUsers: [],
        }
    },
    computed: {
        appraiserUserOptions() {
            return Object.keys(this.fakeAppraisers)
                .filter(key => {
                    if (this.appraiserGroups.length > 0)
                        return this.appraiserGroups.includes(key)
                    return true;
                })
                .map(key => this.fakeAppraisers[key])
                .flat();
        },
        getOrderFileName() {
            if (!this.formValues.order_file)
                return "";
            const url = new URL(this.formValues.order_file);
            return url.pathname.split('/').pop();
        },
    },
    methods: {
        onDownloadOrderFile: function() {
            window.open(this.formValues.order_file);
        },
        handleUpload: function(file) {
            this.$emit('uploaded-order-file', file);
        },
    },
}

</script>

<style scoped>
.breakable-button {
    white-space: normal;
    word-break: break-word;
}
.form-section{
    background-color: #dedede;
    padding:20px 10px;
    margin:15px 0;
}
.checkbox-property {
    height: 45px;
}
.align-tr{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

}
</style>