import Vue from "vue"
import VueFormulate from "@braid/vue-formulate"

import TextInput from "@/../apps/streamline/vue/components/inputs/TextInput.vue";
import DateInput from "@/../apps/streamline/vue/components/inputs/DateInput.vue";
import TextAreaInput from "@/../apps/streamline/vue/components/inputs/TextAreaInput.vue";
import SelectInput from "@/../apps/streamline/vue/components/inputs/SelectInput.vue";
import FileInput from "@/../apps/streamline/vue/components/inputs/FileInput.vue";

const defaultProps = [
    "prependIcon",
    "errorMessage",
    "showErrorMessage",
    "pattern",
]

Vue.use(VueFormulate, {
    components: {
        TextInput,
        DateInput,
        TextAreaInput,
        SelectInput,
        FileInput,
    },
    library: {
        text: {
            classification: "text",
            component: "TextInput",
            slotProps: {
                component: [ ...defaultProps ]
            }
        },
        number: {
            classification: "text",
            component: "TextInput",
            slotProps: {
                component: [ ...defaultProps, "min", "max" ]
            }
        },
        email: {
            classification: "text",
            component: "TextInput",
            slotProps: {
                component: [ ...defaultProps ]
            }
        },
        date: {
            classification: "text",
            component: "DateInput",
            slotProps: {
                component: [ ...defaultProps, "min", "max", "fromToday", "toToday" ]
            }
        },
        textarea: {
            classification: "text",
            component: "TextAreaInput",
            slotProps: {
                component: [ ...defaultProps ]
            }
        },
        select: {
            classification: "text",
            component: "SelectInput",
            slotProps: {
                component: [ ...defaultProps ]
            }
        },
        file: {
            classification: "file",
            component: "FileInput",
            slotProps: {
                component: [ ...defaultProps, "accept", "uploader" ]
            }
        },
    },
    classes: {
        outer: "form-group",
        input: "form-control",
        inputHasErrors: "is-invalid",
        help: "form-text text-muted",
        errors: "list-unstyled text-danger"
    },
})
