<template>
    <div class="panel panel-default">
        <div class="panel-heading">
                {{ $t("legal:extract")}}: {{sourceRealEstateDisplay}}
                <!-- {{legal_status.real_estate.re_key}} - {{legal_status.real_estate.address}} -->
                <span
                    v-if="legal_status.legal_file !== null"
                    class="btn btn-xs btn-outline btn-rounded btn-info view-pdf m-l-10" 
                    :href="legal_status.legal_file"
                    :data-title="legal_status.name"
                >
                    {{ $t("legal:look_pdf")}}
                </span>
                <a
                    v-else
                    class="btn btn-xs btn-outline btn-rounded btn-info m-l-10"
                    :href="manual_search_link"
                    target="_blank"
                >
                    {{ $t("legal:manual_search:text") }}
                </a>
                <span
                    v-if="current_user && current_user.is_superuser"
                    class="btn btn-xs btn-outline btn-rounded btn-info m-l-10" 
                    @click="parseLegalStatus"
                >
                    {{  $t("legal:refresh_owners") }}
                    <div>(vidno samo administratorjem)</div>
                </span>
                <br />
                <!-- <span
                    class="text-danger small custom-cursor-pointer"
                    v-tooltip="`${$t('legal:reset_for')}: ${sourceRealEstateDisplay}`"
                    @click="$emit('delete-legal-status', legal_status.id)"
                >
                    {{ $t("legal:reset") }}
                </span> -->
            <div class="panel-action">
                <a href="#" data-perform="panel-collapse"><i class="ti-minus"></i></a> 
            </div>
        </div>
        <div class="panel-wrapper collapse in vld-parent">

            <loading 
                :active="loading_legal_status"
                :can-cancel="false" 
                :is-full-page="false"
            />

            <div class="panel-body">
                <p>{{  $t("legal:zk_date") }} {{legal_status.created}}.</p>

                <div :class="{'col-md-4': !alignContentInColumn, 'col-md-12': alignContentInColumn }">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{  $t("depository:owner_title").toUpperCase() }}</th>
                                <th>{{  $t("depository:share").toUpperCase() }}</th>
                                <th v-if="detailsVisible">{{ $t("legal:details").toUpperCase() }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(owner, idx) in legal_status.legal_owners" :key="'lowner_'+idx">
                                <td>{{idx+1}}</td>
                                <td>
                                    <span class="font-medium">{{owner.full_name}}</span><br>
                                    <span class="text-muted">{{owner.address}}</span>
                                </td>
                                <td>
                                    <span
                                        class="label label-success label-rouded"
                                        :class="{'label-success': owner.share.includes('1/1'), 'label-warning': !owner.share.includes('1/1')}"
                                    >
                                        {{owner.share}}
                                    </span>
                                </td>
                                <td v-if="detailsVisible">
                                  <span class="text-muted">{{owner.details}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div :class="{'col-md-8': !alignContentInColumn, 'col-md-12': alignContentInColumn }">
                    <div v-if="!legal_status.data">
                        {{ $t("legal:error_messages:zk_parsing_failed") }}
                        <span v-if="legal_status.legal_file">
                            {{ $t("legal:info_messages:open_document") }}
                            <span
                                class="btn btn-xs btn-outline btn-rounded btn-info view-pdf m-l-10" 
                                :href="legal_status.legal_file"
                                :data-title="legal_status.name"
                            >
                              {{ $t("legal:look_pdf")}}
                            </span>
                        </span>
                    </div>

                    <div v-else-if="legal_status.legal_items.length" class="table-responsive">
                        
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>{{  $t("legal:fact").toUpperCase() }}</th>
                                    <th>{{ $t("legal:details").toUpperCase() }}</th>
                                    <th class="text-nowrap">{{ $t("legal:risk_assessment").toUpperCase()}}</th>
                                    <!-- <th>UREDI</th> -->
                                </tr>
                            </thead>
                            <tbody v-if="!legal_status.legal_items.length">
                                <tr>
                                    <td class="text-center" colspan="3">
                                      {{ $t("legal:no_legal_restrictions")}}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <template v-for="(legal_item, idx) in legal_status.legal_items.filter(i => !i.parent_item).sort((i, j) => i.id - j.id)">
                                    <tr :key="'litem-tr-'+idx">
                                        <td>
                                            {{legal_item.name}}
                                        </td>
                                        <td>
                                            {{legal_item.note}}
                                        </td>
                                        <td>
                                            <legal-rating-selector
                                                v-if="legal_item.rating"
                                                v-model="legal_item.rating"
                                                :rating_choices="legal_rating_choices"
                                                :rating_edited="legal_item.rating_edited"
                                                @select="updateLegalItem(legal_item)"
                                                @recreate-rating-click="recreateRating(legal_item)"                                                
                                            />
                                        </td>
                                        <!-- <td class="text-center">
                                            <i v-tooltip="'Uredi opombo'" class="fa fa-pencil text-inverse"></i>
                                        </td> -->
                                    </tr>
                                    <tr v-for="(child_legal_item, child_idx) in legal_item.child_legal_items" :key="'litem-'+idx+'-details-tr-'+child_idx">
                                        <td style="border-top: none;"></td>
                                        <td>
                                            {{child_legal_item.name}} - {{child_legal_item.note}}
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import ApiService from "@/components/utils/api.service.js";

import LegalRatingSelector from "./LegalRatingSelector"

export default {
    components: {
        Loading,
        LegalRatingSelector,
    },
    model: {
        prop: 'legal_status',
        event: 'change'
    },
    props: {
        legal_status: {
            type: Object,
            required: true
        },
        current_user: {
            type: Object,
            required: false,
        },
        manual_search_link: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            legal_rating_choices: [],
            loading_legal_status: false,
        }
    },
    methods: {
        parseLegalStatus() {
            this.loading_legal_status = true;
            ApiService
                .get("/amas/analysis/legal/api/legal-status/" + this.legal_status.id + "/?parse=true")
                .then(response => {
                    this.$emit("change", response.data);
                })
                .then(() => {
                    this.loading_legal_status = false;
                })
        },
        getLegalRatingChoices() {
            ApiService
                .get("/amas/analysis/legal/api/legal-rating/list/public")
                .then(response => {
                    this.legal_rating_choices = response.data;
                })
        },
        _handleLegalItemUpdate(data) {
            this.legal_status.legal_items
                .filter(i => i.id===data.id)
                .map(i => {
                    i.rating = data.rating;
                    i.rating_edited = data.rating_edited;
                    i.user_note = data.user_note;
                    return i
                })
            this.$emit("change", this.legal_status);
        },
        updateLegalItem(legal_item) {
            ApiService
                .put(
                    "/amas/analysis/legal/api/legal-item/" + legal_item.id + "/",
                    legal_item
                )
                .then(response => {
                    this._handleLegalItemUpdate(response.data);
                })
        },
        recreateRating(legal_item) {
            ApiService
                .get("/amas/analysis/legal/api/legal-item/" + legal_item.id + "/?recreate_rating=true")
                .then(response => {
                    this._handleLegalItemUpdate(response.data);
                })
        }
    },
    computed: {
        detailsVisible() {
            if (this.legal_status) {
                for (const owner of this.legal_status.legal_owners)
                    if (owner.details)
                        return true;
            }
            return false;
        },
        alignContentInColumn() {
            if (this.detailsVisible)
                return true;
            return (this.legal_status.legal_items.length === 0);
        },
        sourceRealEstateDisplay() {
            let res;
            if (this.legal_status && this.legal_status.data && this.legal_status.data["NEPREMICNINA"] &&
                this.legal_status.data["NEPREMICNINA"]["id_znak"] !== undefined) {
                let raw = this.legal_status.data["NEPREMICNINA"]["id_znak"];
                if ( raw ) {
                    if( raw.includes("parcela") ) { // parcela 1920 211
                        let re = /parcela ([0-9/*]+) ([0-9/*]+)/;
                        res = "parcela " + raw.match(re).splice(1).join("-");
                    } else { // del stavbe 1772-1627-32
                        // re = /del stavbe (\d+)-(\d+)-(\d+)/;
                        res = raw;
                    }
                }
            } else if (this.legal_status && this.legal_status.real_estate) {
                res = this.legal_status.real_estate.re_key;
            }
            return res;            
        }
    },
    mounted() {
        this.getLegalRatingChoices()
    }
}
</script>
