<template>
    <div>
        <table class="table table-bordered table-responsive">
            <tbody>
                <tr>
                    <td><strong>{{ $t('aa:number')}}</strong></td>
                    <td v-for="permit in acts" :key="permit.STEV_ZAD">
                        {{permit.STEV_ZAD}}
                    </td>
                </tr>
                <tr>
                    <td><strong> {{$t('aa:executive_body')}}</strong></td>
                    <td v-for="permit in acts" :key="permit.STEV_ZAD">
                        {{permit.NAZ_UPR_ORG}}
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ $t('aa:procedure')}}</strong></td>
                    <td v-for="permit in acts" :key="permit.STEV_ZAD">
                        {{permit.NAZ_UPR_POS}}
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ $t('aa:building:description')}}</strong></td>
                    <td v-for="permit in acts" :key="permit.STEV_ZAD">
                        {{permit.OBJ}}
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ $t('aa:date:issued')}}</strong></td>
                    <td v-for="permit in acts" :key="permit.STEV_ZAD">
                        {{permit.DAT_IZD}}
                    </td>
                </tr>
                <tr>
                    <td><strong> {{ $t('aa:date:legally')}}</strong></td>
                    <td v-for="permit in acts" :key="permit.STEV_ZAD">
                        {{permit.MAX_DAT_PRA}}
                    </td>
                </tr>
                <tr>
                    <td><strong> {{ $t('aa:parcel')}}</strong></td>
                    <td v-for="permit in acts" :key="permit.STEV_ZAD">
                        <div v-for="parcel in permit.parcels_same_stev_zad" :key="parcel.re_key">
                            <a class="open-modal" 
                                :href="getOpenPropertyModalUrl(parcel.re_key)">
                                {{parcel.re_key}}
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['acts'],
}
</script>
