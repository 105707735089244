var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"white-box"},[_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-sm btn-info btn-outline",on:{"click":_vm.openCreateOrderModal}},[_vm._v(" Novo povpraševanje ")])])]),_c('base-order-table',{attrs:{"items":_vm.orders,"loading":_vm.loading_orders},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{class:[
                'button-holder',
                {
                    'btn-group dropdown': _vm.checkOrderStatus(item, _vm.statuses.ACCEPTED, _vm.statuses.DRAFT, _vm.statuses.REQUEST_FOR_QUOTE),
                }]},[_c('button',{staticClass:"btn btn-sm btn-info btn-outline",on:{"click":function($event){return _vm.openOrderDetailsModal(item.id)}}},[_vm._v(" Odpri ")]),(_vm.checkOrderStatus(item, _vm.statuses.ACCEPTED, _vm.statuses.DRAFT, _vm.statuses.REQUEST_FOR_QUOTE))?[_c('div',{staticClass:"btn btn-sm btn-info btn-outline dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown"}},[_c('span',{staticClass:"caret"})]),_c('ul',{staticClass:"dropdown-menu",attrs:{"role":"menu"}},[(_vm.checkOrderStatus(item, _vm.statuses.ACCEPTED))?_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.changeMode(item)}}},[_vm._v(" Ustvari naročilo ")])]):_vm._e(),(_vm.checkOrderStatus(item,
                            _vm.statuses.DRAFT, _vm.statuses.REQUEST_FOR_QUOTE))?_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.cancelOrder(item)}}},[_vm._v(" Prekliči naročilo ")])]):_vm._e()])]:_vm._e()],2)]}}])}),_c('bootstrap3-modal',{attrs:{"title":"Novo povpraševanje","name":"createOrderModal","width":"90%"}},[_c('CreateOrder',{on:{"order-submitted":_vm.handleOrderSubmitted,"order-saved":_vm.handleOrderSubmitted}})],1),_c('bootstrap3-modal',{attrs:{"title":'Podrobnosti povpraševanja ' + _vm.selected_order_id,"name":"orderDetailsModal","width":"90%"}},[(_vm.selected_order_id)?_c('OrderDetails',{attrs:{"orderId":_vm.selected_order_id},on:{"order-submitted":_vm.handleOrderSubmitted,"order-saved":_vm.handleOrderSubmitted,"move-depository":_vm.handleMoveDepository}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }