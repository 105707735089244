<template>
    <div class="container-fluid vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="row">
            <FormulateForm
                v-model="formValues"
            >
                <div class="col-sm-4">
                    <FormPanelSection
                        title="Podatki o poročilu"
                    >
                        <FormulateInput
                            name="appraisal_date"
                            :label="$t('depository:appraisal_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIcon="ti-calendar"
                        />
                        <FormulateInput
                            name="on_site_date"
                            :label="$t('depository:on_site_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIcon="ti-calendar"
                        />
                        <FormulateInput
                            name="report_date"
                            :label="$t('depository:report_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIcon="ti-calendar"
                        />
                    </FormPanelSection>

                    <FormPanelSection
                        title="AMAS Analiza"
                        v-if="amasAnalysisEnabled"
                    >
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <template v-if="formValues && formValues.analysis_session_appraiser">
                                <button
                                    class="btn open-modal"
                                    :href="`/amas/analysis/${formValues.analysis_session_appraiser}?popup=true`"
                                    data-iframe="true" data-modal-width="95%" data-modal-height="90%" data-toggle="tooltip"
                                >
                                    Odpri v AMAS
                                </button>
                                <button class="btn" @click="retrieveFromAMAS" :disabled="isReadOnly">Prenesi podatke iz AMAS</button>
                            </template>
                            <button v-else class="btn" @click="appraiseInAMAS">Oceni v AMAS</button>
                        </div>
                    </FormPanelSection>

                    <FormPanelSection
                        title="Lastniki"
                    >
                        <table class="table">
                            <FormulateInput
                                type="group"
                                name="orderitempropertyowner_set"
                                add-label="+ Dodaj lastnika"
                                :repeatable="true&&!isReadOnly" 
                            >
                                <tr class="align-tr">
                                    <td>
                                        <FormulateInput name="owner_title" type="text" :label="$t('depository:owner_title')"  :disabled="isReadOnly"/>
                                    </td>
                                    <td>
                                        <FormulateInput name="share" :label="$t('depository:share')" placeholder="1/2"  :disabled="isReadOnly" />
                                    </td> 
                                </tr>
                                <tr class="align-tr">
                                    <td>
                                        <FormulateInput name="owner_phone" type="text" :label="$t('depository:owner_phone')"  :disabled="isReadOnly"/>
                                    </td>
                                    <td>
                                        <FormulateInput name="owner_email" type="text" :label="$t('depository:owner_email')"  :disabled="isReadOnly"/>
                                    </td>
                                </tr>
                                <tr class="display-block">
                                    <td class="display-block">
                                        <FormulateInput class="w-100" name="owner_address" type="text" :label="$t('depository:owner_address')"  :disabled="isReadOnly"/>
                                    </td>
                                </tr>
                            </FormulateInput>
                        </table>
                    </FormPanelSection>
                </div>

                <div class="col-sm-8">
                    <FormPanelSection
                        v-if="formValues && formValues.notes_correction"
                        :title="$t('depository:notes_correction')"
                    >
                        <div style="white-space: pre-wrap;">{{ formValues.notes_correction }}</div>
                    </FormPanelSection>

                    <FormPanelSection
                        title="Predmet cenitve"
                    >
                        <OrderFormRealEstateTable
                            v-if="formValues.orderitemproperty_set"
                            :properties="formValues.orderitemproperty_set"
                            :isReadOnly="isReadOnly"
                        />
                    </FormPanelSection>

                    <FormPanelSection
                        title="Opisni podatki"
                    >
                        <div class="row">
                            <div class="col-sm-2">
                                <FormulateInput  name="post_office_id" :label="$t('depository:post_office_id')" type="text" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput  name="post_office_name" :label="$t('depository:post_office_name')" type="text" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-4">
                                <FormulateInput  name="country" :label="$t('depository:country')" type="text" :disabled="isReadOnly" />
                            </div>

                            <div class="col-sm-12">
                                <FormulateInput name="short_description" :label="$t('depository:short_description')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="construction_description" :label="$t('depository:construction_description')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="access_description" :label="$t('depository:access_description')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-4">
                                <FormulateInput name="total_size" :label="$t('depository:total_size')" type="number"  :disabled="isReadOnly" />
                            </div>
                            <!-- <div class="col-sm-4">
                                <FormulateInput name="total_size_of_improvements" :label="$t('depository:total_size_of_improvements')" type="number"  :disabled="isReadOnly" />
                            </div> -->
                            <div class="col-sm-4">
                                <FormulateInput name="usable_size" :label="$t('depository:usable_size')" type="text"  :disabled="isReadOnly" />
                            </div>
                        </div>
                    </FormPanelSection>
                </div>
            </FormulateForm>
        </div>
        
        <div class="row">
            <FormulateForm
                v-model="formValues"
            >
                <div class="col-sm-12">
                    <FormPanelSection
                        title="Ocena vrednosti"
                    >
                        <div class="row">
                            <div class="col-sm-12">
                                <FormulateInput name="msov_type" :label="$t('depository:msov_type')" type="select" :options="selectOptions.msov_type"  :disabled="isReadOnly" />
                            </div>
                                <!-- <div class="col-sm-12" >
                                <FormulateInput name="time_to_sell" :label="$t('depository:time_to_sell')" type="number"  :disabled="isReadOnly" />
                            </div> -->
                            <div class="col-sm-12">
                                <FormulateInput name="occupant_type" :label="$t('depository:occupant_type')" type="select" :options="selectOptions.occupant_type"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="real_estate_right" :label="$t('depository:real_estate_right')" type="select" :options="selectOptions.real_estate_right"   :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="market_value_total" :label="$t('depository:market_value_total')" type="text" validation="required" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="liquidation_value" :label="$t('depository:liquidation_value')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="market_value_constuction_completed" :label="$t('depository:market_value_constuction_completed')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="is_building_complete" :label="$t('depository:is_building_complete')" type="select" :options="[
                                    { value: '', label: ''},
                                    { value: '1', label: 'Da' },
                                    { value: '0', label: 'Ne' },
                                ]" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6" >
                                <FormulateInput name="appraisal_type_market_rate_approach" :label="$t('depository:appraisal_type_market_rate_approach')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6" >
                                <FormulateInput  name="is_adaptation" :label="$t('depository:is_adaptation')" type="select"  :options="[
                                    { value: '', label: ''},
                                    { value: '1', label: 'Da' },
                                    { value: '0', label: 'Ne' },
                                ]" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="appraisal_type_income_based_approach" :label="$t('depository:appraisal_type_income_based_approach')"  type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="capitilisation_value" :label="$t('depository:capitilisation_value')"  type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="appraisal_type_purchase_value_approach" :label="$t('depository:appraisal_type_purchase_value_approach')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="justification_for_appraisal" :label="$t('depository:justification_for_appraisal')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="notes" :label="$t('depository:notes')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                        </div>
                    </FormPanelSection>
                </div>
            </FormulateForm>
        </div>

            
        <div class="row" v-if="!isReadOnly">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Akcije"
                >
                    <div class="text-center">
                        <button class="btn btn-outline btn-info btn-sm" @click="saveDraftOrderItem">
                            Shrani osnutek
                        </button>

                        <button class="btn btn-outline btn-success btn-sm m-l-10" @click="saveAndSubmitOrderItem">
                            Shrani in oddaj cenitev
                        </button>
                    </div>
                </FormPanelSection>
            </div>
        </div>

        <div class="row" v-if="actionsEnabled && !isAcceptedAndClosed">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Akcije"
                >
                    <div class="m-b-10">
                        <textarea
                            v-model="formValues.notes_correction"
                            class="form-control"
                            :placeholder="$t('depository:notes_correction')"
                            rows="3"
                        ></textarea>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-sm btn-warning" @click="requestUpdate">
                            Zahtevaj popravek
                        </button>

                        <button class="btn btn-sm btn-success m-l-10" @click="acceptOrder">
                            Sprejmi cenitev
                        </button>
                    </div>
                </FormPanelSection>
            </div>
        </div>

    </div>
</template>
<script>
import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

import optionsmixin from "./mixins/optionsmixin.js";
import OrderFormRealEstateTable from "./order_form_parts/OrderFormRealEstateTable.vue";
import FormPanelSection from "./FormPanelSection.vue";

export default {
    components: {
        OrderFormRealEstateTable,
        FormPanelSection,
    },
    mixins: [ optionsmixin ],
    props : {
        orderItemId: {
            type: [String, Number],
            required: false
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        actionsEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        amasAnalysisEnabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed:{
        isEditMode: function() {
            return this.orderItemId != undefined;
        },
        isReadOnly: function(){
            return this.readOnly || (
                this.formValues &&
                this.formValues.task &&
                this.formValues.task.task_status &&
                ["Submitted", "Accepted and closed"].includes(this.formValues.task.task_status.name));
        },
        isAcceptedAndClosed: function(){
            return this.formValues &&
                this.formValues.task &&
                this.formValues.task.task_status &&
                ["Accepted and closed"].includes(this.formValues.task.task_status.name);
        },
    },
    methods: {
        getOrderItem: function(){
            this.loading = true;
            return ApiService
                .get("/streamline/api/order-item/" + this.orderItemId)
                .then(response => {
                    this.formValues = this._convertBooleanSelect(response.data);
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju cenitve",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        submitOrderItemAction: function( actionName, postData, successMessage, errorMessage ){
            this.loading = true;
            return ApiService
                .post(`/streamline/api/order-item/${this.formValues.id}/${actionName}/`, postData)
                .then(response => {
                        Swal({
                            title: "",
                            text: successMessage,
                            icon: "success",
                        });
                        this.formValues = response.data;
                        this.loading = false;
                        this.$emit("order-item-action-change");
                        return response.data;
                    })
                .catch(error => {
                    Swal({
                        title: errorMessage,
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        saveAndSubmitOrderItem: function() {
            this
                .updateOrderItem(this.formValues)
                .then(_orderItem => {
                    this.submitOrderItemAction("submit", {}, "Cenitev oddana", "Napaka pri oddaji cenitve");
                });
        },
        saveDraftOrderItem: function() {
            this
                .updateOrderItem(this.formValues)
                .then(_orderItem => {
                    Swal({
                        title: "",
                        text: "Osnutek shranjen",
                        icon: "success",
                    });
                    this.$emit("order-item-saved");
                });
        },
        _convertBooleanSelect: function(data) {
            const booleanSelectMap = {
                null: "",
                [true]: "1",
                [false]: "0",
            };
            data["is_building_complete"] = booleanSelectMap[data["is_building_complete"]];
            data["is_adaptation"] = booleanSelectMap[data["is_adaptation"]];
            return data;
        },
        updateOrderItem: function() {
            this.loading = true;
            return ApiService
                .put(`/streamline/api/order-item/${this.formValues.id}/`, this.formValues)
                .then((response) => {
                    this.formValues = this._convertBooleanSelect(response.data);
                    return this.formValues;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri posodabljanju cenitve",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        acceptOrder: function(data) {
            this.submitOrderItemAction("accept", {}, "Cenitev sprejeta", "Napaka pri sprejemanju cenitve");
        },
        requestUpdate: function(data) {
            this.submitOrderItemAction("reject", {"notes": this.formValues.notes_correction}, "Cenitev poslana v dopolnitev", "Napaka pri pošiljanju cenitve v dopolnitev");
        },
        appraiseInAMAS: function() {
            this.loading = true;
            return ApiService
                .post(`/streamline/api/order-item/${this.formValues.id}/analysis`, {
                    re_keys: this.formValues.orderitemproperty_set.map(property => property.re_key),
                })
                .then(res => {
                    if (res.data) {
                        this.formValues.analysis_session_appraiser = res.data.analysis_session_appraiser;
                        Swal({
                            title: "",
                            text: "Analiza uspešno ustvarjena. Do nje lahko dostopate s klikom na gumb 'Odpri v AMAS'.",
                            icon: "success",
                        });
                    } else {
                        Swal({
                            title: "",
                            text: "Napaka pri ustvarjanju analize",
                            icon: "error",
                        });
                    }
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri ustvarjanju analize v AMAS",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        retrieveFromAMAS: function() {
            this.loading = true;
            ApiService.get(`/streamline/api/order-item/${this.formValues.id}/analysis`)
            .then(res => {
                if (res.data) {
                    this.formValues = {...this.formValues, ...res.data};

                    Swal({
                        title: "",
                        text: "Podatki uspešno pridobljeni",
                        icon: "success",
                    });
                }
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri pridobivanju podatkov iz AMAS",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.loading = false;
            })
        },
    },
    data() {
        return {
            formValues: {},
            loading: false,
        }
    },
    mounted() {
        this.getOrderItem();
    }

}
</script>

<style>
.form-section{
    background-color: #dedede;
    padding:20px 10px;
    margin:15px 0;
}
.checkbox-property {
    height: 45px;
}
.align-tr{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

}
</style>