<template>
<div class="vld-parent">
    <loading 
        :active="loading"
        :can-cancel="false" 
        :is-full-page="false"
    />

    <p v-if="pagination">
        <small v-if="items.length > 0">
        {{$t("general:showing")}} {{(page - 1) * items_per_page + 1 }} {{$t("general:to")}} {{(page - 1) * items_per_page + paginated_items.length }} {{$t("general:of_all")}} {{ items.length }} {{$t("analysis:ct:transactions")}}
        </small>
        <small v-else>
        {{$t("general:search:no_results")}}
        </small>
    </p>

    <table class="table text-center table-striped m-0">
        <thead>
            <tr>
                <th
                    v-for="col in columns"
                    v-bind:key="col.id"
                    class="text-center"
                    v-bind:class="getSortedClass(col.sort_key)"
                >
                    <a href="#" v-if="sortable&&col.sort_key" v-on:click.prevent="sortBy(col.sort_key)">{{col.display_name}}</a>
                    <span v-else>{{col.display_name}}</span>
                    <help-icon v-if="col.help_icon_slug" class="m-l-5" :slug="col.help_icon_slug" />
                </th>
            </tr>
        </thead>

        <tbody>
            <template v-if="!loading && paginated_items.length == 0">
                <td :colspan="columns.length">
                    <p>{{no_items_text}}</p>
                </td>
            </template>

            <template v-for="item in paginated_items">
                
                <results-row
                    v-bind:key="item.id"
                    :item="item"
                    :columns="columns.map(i=>i.key)"
                    :row_expanded="showAdditionalItems(item.transaction_id)"
                    @labelclicked="toggleAdditionalTransactionitems(item.transaction_id)"
                    @comparable-clicked="$emit('comparable-clicked', $event);"
                >
                </results-row>
<!-- 
                <tr 
                    v-if="showAdditionalItems(item.transaction_id) && getTransactionItems(item.transaction_id, item.id)=='loading'"
                    v-bind:key="item.id+'-loading-row'"
                >
                    <td :colspan="columns.map(i=>i.key).length">
                        Nalaganje ...
                    </td>
                </tr>

                <results-row
                    v-else-if="showAdditionalItems(item.transaction_id)"
                    v-for="sub_item in getTransactionItems(item.transaction_id, item.id)"
                    v-bind:key="sub_item.id"
                    :item="sub_item"
                    :columns="columns.map(i=>i.key)"
                    :hide_column_values="['transaction_id']"
                /> -->

            </template>
            
            <template>
                <slot name="additional-rows" v-bind:colspan="columns.length">
                </slot>
            </template>
        </tbody>
    </table>
    
    <div class="text-center" v-if="pagination">
        <pagination
            v-model="page"
            :records="items.length"
            :per-page="items_per_page"
            :options="{
                edgeNavigation: true
            }"
        />
    </div>
</div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from 'vue-pagination-2';
import HelpIcon from "@/components/widgets/HelpIcon";

import Vue from "vue";

import ApiService from "@/components/utils/api.service.js"
import Utils from "@/components/utils/utils.js"

import ResultsRow from "./ResultsTableRow";

export default {
    props:  {
        items: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
        },
        hide_columns: {
            type: Array,
            default: () => []
        },
        no_items_text: {
            type: String
        },
        pagination: {
            type: Boolean,
            default: true,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        HelpIcon,
        Loading,
        Pagination,
        "results-row": ResultsRow
    },
    data: function () {
        return {
            // Paginator
            page: 1,
            items_per_page: 20,

            sort_key: "",
            all_columns: [
                {   
                    key: "transaction_id",
                    display_name: this.$t("analysis:transaction:id"),
                    sort_key: "transaction_id"
                },
                {   
                    key: "address",
                    display_name: this.$t("general:real_estate"),
                    sort_key: "address"
                },
                // {
                //     key: "transaction_date",
                //     display_name: "Datum transakcije",
                //     sort_key: "transaction_date"
                // },
                {   
                    key: "component_size",
                    display_name: this.$t("general:contract:size"),
                    sort_key: "component_size"
                },
                {
                    key: "sizes",
                    display_name: this.$t("general:area:plural"),
                    help_icon_slug: "amas-comparable-search-ren-povrsine",
                },
                {   
                    key: "building_year_built",
                    display_name: this.$t("general:year_built_and_renovations"),
                    sort_key: "building_year_built",
                    help_icon_slug: "amas-comparable-search-leto-izgradnje-in-obnove"
                },
                {   
                    key: "transaction_amount_gross",
                    display_name: this.$t("analysis:transaction:contract:price"),
                    sort_key: "transaction_amount_gross",
                    help_icon_slug: "pogodbena-cena",
                },
                {
                    key: "transaction_amount_m2",
                    display_name: this.$t("analysis:ct:price_per_squared_meter"),
                    sort_key: "transaction_amount_m2",
                    help_icon_slug: "pogodbena-cena-m2",
                },
                {   
                    key: "match_score",
                    display_name: this.$t("general:match"),
                },
                {   
                    key: "options",
                    display_name: this.$t("analysis:ct:selected_comparable"),
                },
                {   
                    key: "options",
                    display_name: this.$t("analysis:ct:wider_range"),
                },
            ],
            
            uncollapsed_transaction_ids: [],
            additional_transaction_data: {},
        }
    },
    methods: {
        // Sorting methods
        sortBy: function(sort_key) {
            if(this.sort_key===sort_key) {
                this.sort_key = "-"+sort_key;
            } else if(this.sort_key==="-"+sort_key) {
                this.sort_key = null;
            } else{
                this.sort_key = sort_key;
            }
        },
        getSortedClass: function(sort_key) {
            if(!sort_key || !this.sortable) {return ""}
            let cls = "sorting";
            if (this.sort_key===sort_key) {
                cls+=" sorting-asc";
            } else if (this.sort_key==="-"+sort_key) {
                cls+=" sorting-desc";
            }
            return cls;
        },
        
        // Fetch and expand transaction items methods
        toggleAdditionalTransactionitems: async function(transaction_id){
            if(this.uncollapsed_transaction_ids.includes(transaction_id)) {
                Utils.removeFromArray(this.uncollapsed_transaction_ids, transaction_id);
            } else {
                Utils.addToArray(this.uncollapsed_transaction_ids, transaction_id);
            }
            if(!this.additional_transaction_data[transaction_id]) {
                Vue.set(this.additional_transaction_data, transaction_id, "loading");
                this.fetchTransactionItems(transaction_id);
            }
        },
        getTransactionItems: function(transaction_id, exclude_id){
            let items = this.additional_transaction_data[transaction_id];
            if (Array.isArray(items)) {return items.filter(item => item.id != exclude_id);}
            return items;
        },
        fetchTransactionItems: function(transaction_id) {
            ApiService
                .get("market/api/search", { params: {
                    transaction_id: transaction_id
                }})
                .then(response => {
                    Vue.set(this.additional_transaction_data, transaction_id, response.data) 
                })
                .catch(error => {
                    console.log(error);
                    // Swal.fire("Napaka", "", "error");
                })
        },
        showAdditionalItems: function(transaction_id) {
            return this.uncollapsed_transaction_ids.includes(transaction_id);
        },

    },
    computed: {
        columns: function() {
            return this.all_columns.filter(obj => !this.hide_columns.includes(obj.key));
        },
        sortedItems: function() {
            if( !this.sort_key ) {
                return this.items;
            }
            let sort_order = 1;
            let sort_key = this.sort_key;
            if(sort_key[0] === "-") { // Descending
                sort_order = -1;
                sort_key = sort_key.slice(1);
            }
            return [...this.items].sort((a, b) => {
                let a_val = a[sort_key];
                let b_val = b[sort_key];
                if (a_val === b_val) {return 0;}
                if (a_val === null) {return 1;}
                if (b_val === null) {return -1;}
                if (a_val < b_val) {return -1 * sort_order;}
                if (a_val > b_val) {return 1 * sort_order;}
            });
        },
        paginated_items: function() {
            let items = this.sortedItems;
            if(this.pagination) {
                let start = (this.page - 1) * this.items_per_page;
                return items.slice(start, start + this.items_per_page);
            } else {
                return items;
            }
        }
    },
}
</script>

<style>
    /* Pagination */
    p.VuePagination__count, li.VuePagination__pagination-item-first-page, li.VuePagination__pagination-item-last-page {
        display: none!important;
    }
</style>

<style scoped>
    th.sorting::after {
        content: "\f0dc";
        font-family: fontawesome;
        color: rgba(50, 50, 50, .5);
        float: none;
        padding-left: 10px;
    }
    th.sorting-asc::after {
        content: "\f0de";
    }
    th.sorting-desc::after {
        content: "\f0dd";
    }
    table {
        font-size: 14px;
        font-family: Rubik, sans-serif;
        font-weight: 200;
    }
</style>