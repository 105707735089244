<template>
    <div>
        <base-order-table
            :items="orders"
            :columns="columns"
            :loading="loading_orders"
        >
            <template v-slot:actions="{ item }">
                <button
                    class="btn btn-sm btn-info btn-outline"
                    @click="openOrderDetailsModal(item.id)"
                >
                    Odpri
                </button>
            </template>

            <template v-slot:due_dates="{ item }">
                <span v-tooltip="'Rok za oddajo cenitve'" v-if="item.due_date">
                    <i class="fa fa-paper-plane"></i>
                    {{ item.due_date }}
                </span>
                <span v-else></span>
            </template>

            <template v-slot:statistics="{ item }">
                <order-statistics
                    :order="item"
                />
            </template>
        </base-order-table>

        <!--
            START Modals
        -->
        <bootstrap3-modal
            :title="'Podrobnosti naročila ' + selected_order_id"
            name="orderDetailsModal"
            width="90%"
        >
            <OrderDetails
                v-if="selected_order_id"
                :orderId="selected_order_id"
            />
        </bootstrap3-modal>
        <!--
            END Modals
        -->
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import { Swal } from "@/components/utils/swal.alerts.js";

import BaseOrderTable from "../../components/BaseOrderTable.vue";
import OrderDetails from "./OrderDetails.vue";
import OrderStatistics from "../../components/OrderStatistics";

export default {
    components: {
        BaseOrderTable,
        OrderDetails,
        OrderStatistics,
        "bootstrap3-modal": Bootstrap3Modal,
    },
    data() {
        return {
            orders: [],
            loading_orders: false,
            selected_order_id: null,
            columns: [
                {
                    field: "appraiser",
                    hidden: true,
                },
                {
                    field: "offers_amount",
                    hidden: true,
                },
                {
                    field: "due_dates",
                    label: "Rok izvedbe",
                    slot_name: "due_dates",
                    sort_conversion: item => item.due_date,
                },
                {
                    field: "statistics",
                    label: "Statistika",
                    sortable: false,
                    slot_name: "statistics",
                    hidden: false,
                },
            ],
        }
    },
    methods: {
        getOrders: function(){
            this.loading_orders = true;
            return ApiService
                .get("/streamline/api/order/list", {
                    params: {
                        "process": "depository",
                        "actor": "supplier",
                    }
                })
                .then(response => {
                    this.orders = response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju naročil",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading_orders = false;
                })
        },
        openOrderDetailsModal: function(order_id) {
            this.selected_order_id = order_id;
            this.$modal.show("orderDetailsModal");
        },
    },
    // computed: {
    //     ownerToken: function(){
    //         return "dabb07e4-8d7e-4ab8-a12e-2f1cfe9e5ed8";
    //     }
    // },
    mounted() {
        this.getOrders();
    }

}
</script>
