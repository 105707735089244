<template>
    <order
        :orderId="null"
        :readOnly="false"
        :showAppraisers="true"
        :showOffers="false"
        :showAppraiserSelection="false"
        :showQuoteDueDate="false"
        :mode="'depository'"
        @order-submitted="$emit('order-submitted', $event)"
    />
</template>

<script>
import Order from "../../components/Order.vue"

export default {
    components: { Order },
}
</script>
