export default {
    created() {
        this.statuses = {
            DRAFT: 1,
            REQUEST_FOR_QUOTE: 2,
            IN_SELECTION: 3 ,
            ACCEPTED: 8,
            OPEN: 4,
            IN_PROGRESS: 5,
            REJECTED: 6,
            COMPLETED: 7,
            CANCELED: 9,
        }
    },
    methods: {
        checkOrderStatus(order, ...statuses) {
            return [...statuses].includes(order.status);
        }
    }
}
