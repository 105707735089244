<template>
    <div>
        <div class="row m-b-10">
            <div class="col-md-12">
                
                <div class="pull-right">
                    <a class="btn btn-block btn-outline btn-info btn-xs" 
                        :href="'/amas/analysis/' + session_id + '/transaction/' + transaction_type + '/matrix'"
                    >
                        {{$t("analysis:tabs:adjustment_matrix")}}
                    </a>
                </div>
                <div class="pull-right">
                    <a class="btn btn-block btn-outline btn-info btn-xs"
                        href="#"
                        v-on:click.prevent="getSelectedComparables(true)"
                    >
                        {{$t("analysis:ct:recreate_comparable_transactions")}}
                    </a>
                </div>

                <h4>{{$t("analysis:ct:selected")}} <help-icon slug="dolocanje-pogojev-iskanja-primerljivih-transakcij" /></h4>

                <results-table
                    :items="selected_comparables_table_items"
                    :loading="loading_selected_comparables"
                    :hide_columns="hide_results_columns"
                    :pagination="false"
                    :sortable="true"
                    :no_items_text="$t('analysis:ct:no_comparable_selected')"
                    @comparable-clicked="handleComparableTransactionClicked"
                    ref="comparables_table"
                >
                    <template v-slot:additional-rows="slotProps" v-if="selected_comparables_wide_set_ids.length > 0">
                        <tr>
                            <td class="p-10" :colspan="slotProps.colspan">
                                <a href="#" @click.prevent="toggleShowWideSet">
                                    {{show_wide_set ? $t("general:hide") : $t("general:show")}} {{selected_comparables_wide_set_ids.length}} {{$t("analysis:ct:transactions_in_wide_set")}}
                                </a>
                            </td>
                        </tr>
                    </template>
                </results-table>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8 vld-parent" style="min-height:300px;">
                <h4>{{$t("analysis:ct:comparable_search_conditions")}}</h4>
                <loading 
                    :active="loading_form_data"
                    :can-cancel="false" 
                    :is-full-page="false"
                />
                
                <search-form
                    v-if="form_data"
                    v-model="form_data"
                    :main_property_gps="main_property_gps"
                    :hide_search_options="hide_search_options"
                    @submit="getSearchResults(true)"
                    @reset="getSessionFormData(true)"
                />

            </div>

            <div class="col-md-4" style="min-height:300px;">
                <loading 
                    :active="loading_session_data || loading_search_results"
                    :can-cancel="false" 
                    :is-full-page="false"
                />
                <results-map
                    v-if="main_property_gps"
                    :main_marker="main_property_gps"
                    :center="main_property_gps"
                    :markers="markers"
                    :min-zoom="3"
                    :search_radius="form_data ? form_data.radius : null"
                    ref="map"
                >
                    <template v-slot:details-control="slotProps">
                        <results-table
                            :hide_columns="hide_results_columns"
                            :items="[slotProps.selected_item]"
                            :loading="slotProps.loading_selected_item"
                            :pagination="false"
                            @comparable-clicked="handleComparableTransactionClicked"
                        />
                    </template>
                </results-map>
            </div>

        </div>

        <div class="row m-t-20">
            <div class="col-md-12">
                <h4>{{$t("general:search:results")}}</h4>
                <results-table
                    :hide_columns="hide_results_columns"
                    :items="filtered_search_results"
                    :loading="loading_search_results"
                    :sortable="true"
                    no_items_text="Ni rezultatov iskanja"
                    @comparable-clicked="handleComparableTransactionClicked"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import ApiService from "@/components/utils/api.service.js";

import SearchForm from "./components/SearchForm.vue";
import ResultsTable from "./components/ResultsTable.vue";
import ResultsMap from "./components/ResultsMap.vue";

import store from "./store";
import HelpIcon from '@/components/widgets/HelpIcon.vue';

export default {
    store: store,
    props: {
        session_id: {
            type: Number,
            required: true
        },
        transaction_type: {
            type: String,
            default: "sale"
        },
    },
    components: {
        Loading,
        SearchForm,
        ResultsMap,
        "results-table": ResultsTable,
        HelpIcon,
    },
    data: function () {
        return {
            loading_session_data: false,
            session_data: null,

            form_data: null,
            search_results: [],
            selected_comparables: [],

            loading_selected_comparables: false,
            loading_form_data: true,
            loading_search_results: true,

            show_wide_set: false,
            hide_results_columns: [],
            hide_search_options: []
        }
    },
    methods: {
        getSearchResults: function(save_search_params) {
            this.loading_search_results = true;
            ApiService
                .post(`/amas/analysis/avm/api/${this.session_id}/search`,
                    this.form_data,
                    {params: { save_search_params: save_search_params }}
                )
                .then(response => {
                    this.search_results = response.data;
                })
                .catch(error => {
                    if(error.response && error.response.status===400) {
                        this.handleBadRequestResponse(error.response.data)
                    } else {
                        throw error;
                    }
                })
                .then(() => {
                    this.loading_search_results = false;
                });
        },
        getSelectedComparables: function(recreate = false) {
            this.loading_selected_comparables = true;
            ApiService
                .get(`/amas/analysis/analysis/api/${this.session_id}/transactions`, { params: {
                    comparable: false,
                    transaction_type: this.transaction_type,
                    recreate: recreate,
                }})
                .then(response => {
                    this.selected_comparables = response.data;
                })
                .then(() => {
                    this.loading_selected_comparables = false;
                });
        },
        getSessionFormData: function(reset_search_params) {
            this.loading_form_data = true;
            return ApiService
                .get(`/amas/analysis/avm/api/${this.session_id}/search-parameters`, { params: {
                    transaction_type: this.transaction_type,
                    reset_search_params: reset_search_params,
                }})
                .then(response => {
                    this.form_data = response.data;
                    return
                })
                .then(() => {
                    this.loading_form_data = false;
                });
        },
        handleBadRequestResponse: function(errors) {
            let text = "";
            Object.keys(errors).forEach(function(key) {
                text += key + ": " + errors[key] + "\n";
            });
            alert(text);
        },
        _isInProgress: function(item) {
            return this.$store.state.failedTransactionItemsIds.includes(item.id);
        },
        _isFailed: function(item) {
            return this.$store.state.inprogressTransactionItemsIds.includes(item.id)
        },
        _isSelectedComparable: function(item) {
            if (this.selected_comparables_comparables_ids.includes(item.id)) {
                return true;
            } else if (this.selected_comparables_wide_set_ids.includes(item.id)) {
                return false;
            } else {
                return null;
            }
        },
        addOrUpdateItemToSelected: function(item) {
            let objIndex = this.selected_comparables.findIndex((obj => obj.id == item.id));
            if (objIndex == -1) {
                this.selected_comparables.push(item);
            } else {
                this.$set(this.selected_comparables, objIndex, item)
            }
        },
        handleComparableTransactionClicked: function({item, selection}) {
            if(this._isInProgress(item) || this._isFailed(item)) {return;}
            this.$store.commit("inprogressTransactionItemsIdsAdd", item.id);

            let action, comparable;
            if (item.comparable==null) {
                // Add to selected transactions 
                action = "add";
                comparable = selection==="comparable";
            } else {
                if ( selection==="wide_set" ) {// Remove transaction
                    action = "remove";
                }
                if ( selection==="comparable" && item.comparable===false ) { // Add to comparables
                    action = "add";
                    comparable = true;
                } 
                if ( selection==="comparable" && item.comparable===true ) { // Remove from comparables, keep in wide set
                    action = "add";
                    comparable = false;
                }
            }

            ApiService
                .post(`/amas/analysis/avm/api/${this.session_id}/transactions/manage`, null, {
                    params: { sale_item_id: item.id, comparable: comparable, action: action }
                })
                .then(response => {
                    if (action==="remove") {
                        this.selected_comparables = this.selected_comparables.filter(i => i.sale_object.id != item.id);
                    } else {
                        this.addOrUpdateItemToSelected(response.data);
                    }
                })
                .catch(error => {
                    this.$store.commit("failedTransactionItemsIdsAdd", item.id);
                    throw error;
                })
                .then(() => {
                    this.$store.commit("inprogressTransactionItemsIdsRemove", item.id);
                })
        },

        getSessionData: function(){
            this.loading_session_data = true;
            ApiService
                .get(`/amas/analysis/analysis/api/${this.session_id}`)
                .then(response => {
                    this.session_data = response.data;
                })
                .catch(error => {
                    throw error;
                })
                .then(() => {
                    this.loading_session_data = false;
                })
        },

        toggleShowWideSet: function(){
            this.show_wide_set = !this.show_wide_set;
        } ,

        fetchUserComparableSearchPermissions() {
          const that = this;
          ApiService
            .get("/api/v1/current-user")
            .then(res => {
                const permList = res.data.all_permissions_list;

                const results_permissions = [
                    "analysis.comparable_search__results__show_transaction_id",
                    "analysis.comparable_search__results__show_address",
                    "analysis.comparable_search__results__show_transaction_date",
                    "analysis.comparable_search__results__show_component_size",
                    "analysis.comparable_search__results__show_sizes",
                    "analysis.comparable_search__results__show_building_year_built",
                    "analysis.comparable_search__results__show_transaction_amount_gross",
                    "analysis.comparable_search__results__show_transaction_amount_m2",
                    "analysis.comparable_search__results__show_match_score",
                    "analysis.comparable_search__results__show_options"
                ]

                results_permissions.forEach( function (permission){
                  if (!permList.includes(permission)){
                    that.hide_results_columns.push(permission.substring(22))
                  }
                });

                const search_parameter_permissions = [
                    "analysis.comparable_search__by_rent_or_sale",
                    "analysis.comparable_search__transaction_dates",
                    "analysis.comparable_search__location",
                    "analysis.comparable_search__unit_size",
                    "analysis.comparable_search__parcel_size",
                    "analysis.comparable_search__year_built",
                    "analysis.comparable_search__transaction_amount",
                    "analysis.comparable_search__real_estate_type",
                    "analysis.comparable_search__unit_type",
                    "analysis.comparable_search__apartment_count",
                    "analysis.comparable_search__construction_phase",
                    "analysis.comparable_search__max_size",
                    "analysis.comparable_search__parcel_dedicated_usage",
                    "analysis.comparable_search__sale_share",
                    "analysis.comparable_search__valuation_zone",
                    "analysis.comparable_search__transaction_type",
                    "analysis.comparable_search__transaction_volume",
                    "analysis.comparable_search__exclude_outliers",
                    "analysis.comparable_search__low_quality",
                    "analysis.comparable_search__consider_spatial_plan_area",
                    "analysis.comparable_search__property_model_type",
                    "analysis.price_zone_permission",
                ]

                search_parameter_permissions.forEach( function (permission){
                  if (!permList.includes(permission)){
                    that.hide_search_options.push(permission)
                  }
                });
            })
            .catch(error => {
                throw error;
            });
        },
    },
    mounted() {
        this.$store.dispatch("retrieveMetaOptions");

        this.fetchUserComparableSearchPermissions();

        this.getSessionData();
        // 1. Get sessions's form data
        this.getSessionFormData()
            .then(() => {
                // 3. Submit search form
                this.getSearchResults();
            });
        // 2. Get selected comparables
        this.getSelectedComparables();
    },
    computed: {
        selected_comparables_comparables_ids: function() {
            return this.filtered_selected_comparables.filter(obj => obj.comparable==true).map(obj => obj.id);
        },
        selected_comparables_wide_set_ids: function() {
            return this.filtered_selected_comparables.filter(obj => obj.comparable==false).map(obj => obj.id);
        },

        selected_comparables_table_items: function() {
            let items = this.filtered_selected_comparables;
            if(!this.show_wide_set) {
                items = items.filter(obj => obj.comparable)
            }
            return items;
        },

        filtered_selected_comparables: function() {
            return [...this.selected_comparables].sort((a, b) => {
                return b.score - a.score;
            }).map((i) => {
                return {...i.sale_object, ...{
                    comparable: i.comparable,
                    score: i.score,
                }}
            })
        },
        filtered_search_results: function() {
            return [...this.search_results].sort((a, b) => {
                return b.score - a.score;
            }).map((i) => {
                return {...i, ...{
                    comparable: this._isSelectedComparable(i),
                    score: i.score,
                }}
            })
        },
        all_transactions: function() {
            return this.filtered_selected_comparables.concat(
                this.filtered_search_results.filter(obj => {
                    return !this.selected_comparables
                        .map(i => i.sale_object.id)
                        .includes(obj.id)
                })
            )
        },
        markers: function() {
            return this.all_transactions.map(i => {
                return {
                    id: i.id,
                    gps: i.gps,
                    comparable: i.comparable,
                    score: i.score,
                }
            })
        },

        main_property_gps: function() {
            if(this.session_data && this.session_data.main_property && this.session_data.main_property.real_estate
                && this.session_data.main_property.real_estate.gps && this.session_data.main_property.real_estate.gps.lat) {
                return [
                    this.session_data.main_property.real_estate.gps.lat,
                    this.session_data.main_property.real_estate.gps.lng,
                ]
            }
            else return null;
        }
    }
}
</script>
